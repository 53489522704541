import React, { useEffect, useRef, useCallback, useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl-next';

import ProgressBar from '@atlaskit/progress-bar';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { ConfluenceLongRunningTaskAri } from '@atlassian/ari/confluence/long-running-task';

import { SPACE_OVERVIEW } from '@confluence/named-routes';
import { RoutesContext } from '@confluence/route-manager';
import type { FlagsStateContainer } from '@confluence/flags';
import type { DialogsStateContainer } from '@confluence/dialogs';
import { useSessionData } from '@confluence/session-data';
import { LoadableLazy } from '@confluence/loadable';
import {
	ExperienceTrackerContext,
	RESTORE_SPACE_EXPERIENCE,
	HARD_DELETE_SPACE_EXPERIENCE,
} from '@confluence/experience-tracker';
import { WebItemLocationQuery } from '@confluence/web-item-location';

import type {
	RestoreSpaceMutation as RestoreSpaceMutationType,
	RestoreSpaceMutationVariables,
} from './__types__/RestoreSpaceMutation';
import { RestoreSpaceMutation } from './RestoreSpaceMutation.graphql';
import { HardDeleteSpaceMutation } from './HardDeleteSpaceMutation.graphql';
import { HardDeleteSpaceLongTaskQuery } from './HardDeleteSpaceLongTaskQuery.graphql';
import type {
	HardDeleteSpaceMutation as HardDeleteSpaceMutationType,
	HardDeleteSpaceMutationVariables,
} from './__types__/HardDeleteSpaceMutation';
import type {
	HardDeleteSpaceLongTaskQuery as HardDeleteSpaceLongTaskQueryType,
	HardDeleteSpaceLongTaskQueryVariables,
} from './__types__/HardDeleteSpaceLongTaskQuery';

const i18n = defineMessages({
	errorTitle: {
		id: 'space-directory.space.restore.error-title',
		defaultMessage: 'Something went wrong',
		description: 'Generic error title that indicates something went wrong when restoring a space',
	},
	errorDescription: {
		id: 'space-directory.space.restore.error-description',
		defaultMessage: 'Refresh and try again.',
		description:
			'Description in the generic error flag when something went wrong when restoring a space',
	},
	successTitle: {
		id: 'space-directory.space.delete.success-title',
		defaultMessage: 'Space successfully deleted',
		description: 'Title of the success flag when a space has been deleted',
	},
	deletingProgressTitle: {
		id: 'space-directory.space.delete.progress.title',
		defaultMessage: 'Deleting space',
		description: 'Title of the flag when a space is being deleted',
	},
	deletingProgress: {
		id: 'space-directory.space.delete.progress',
		defaultMessage: 'Deleting...',
		description: 'Progress bar aria label when deleting a space',
	},
});

const POLL_INTERVAL_MS = 500;
const POLL_TIMEOUT_MS = 80000;

export const DeleteSpaceDialogLoader = LoadableLazy({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-DeleteSpaceDialog" */ './DeleteSpaceDialog'))
			.DeleteSpaceDialog,
});

type UseTrashedSpaceProps = {
	spaceKey: string;
	spaceAlias?: string;
	spaceId: string;
	source: string;
	flags: FlagsStateContainer;
	modifyTrashedCache?: (spaceKey: string) => void;
};

export const useTrashedSpace = ({
	spaceKey,
	spaceAlias,
	spaceId,
	source,
	flags,
	modifyTrashedCache,
}: UseTrashedSpaceProps) => {
	const { toUrl, push } = useContext(RoutesContext);
	const intl = useIntl();
	const timeoutRef = useRef<NodeJS.Timeout>();
	const { cloudId, activationId } = useSessionData();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const experienceTracker = useContext(ExperienceTrackerContext);

	const [hardDeleteLongTaskQuery, { data: longTaskData, error: longTaskError, stopPolling }] =
		useLazyQuery<HardDeleteSpaceLongTaskQueryType, HardDeleteSpaceLongTaskQueryVariables>(
			// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
			HardDeleteSpaceLongTaskQuery,
			{
				fetchPolicy: 'network-only',
				pollInterval: POLL_INTERVAL_MS,
			},
		);

	const onError = useCallback(
		(action: any, experience: any, error: any) => {
			void flags.hideFlag('deleting-space-progress');
			void flags.showErrorFlag({
				title: <FormattedMessage {...i18n.errorTitle} />,
				description: <FormattedMessage {...i18n.errorDescription} />,
				isAutoDismiss: true,
			});
			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action,
					actionSubject: 'space',
					source,
					containerType: 'space',
					containerId: spaceId,
				},
			}).fire();
			experience &&
				experienceTracker.fail({
					name: experience,
					error: new Error(error || 'An unknown error has occurred'),
					attributes: {
						spaceId,
					},
				});
		},
		[flags, createAnalyticsEvent, spaceId, source, experienceTracker],
	);

	const [mutateRestoreSpace] = useMutation<RestoreSpaceMutationType, RestoreSpaceMutationVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		RestoreSpaceMutation,
		{
			variables: { spaceKey },
			onError: () =>
				onError('restoreError', RESTORE_SPACE_EXPERIENCE, 'Restore space mutation failed'),
			onCompleted: () => {
				experienceTracker.succeed({
					name: RESTORE_SPACE_EXPERIENCE,
					attributes: {
						spaceId,
					},
				});
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						action: 'restored',
						actionSubject: 'space',
						source,
						containerType: 'space',
						containerId: spaceId,
					},
				}).fire();
				//go to the restored space
				push(toUrl(SPACE_OVERVIEW.name, { spaceKey: spaceAlias || spaceKey }));
			},
			awaitRefetchQueries: true,
			refetchQueries: [
				'StarredSpacesQuery',
				'MyVisitedSpacesWithCurrentSpaceQuery',
				'MyVisitedSpacesQuery',
				{
					query: WebItemLocationQuery,
					variables: {
						location: 'system.user',
					},
				},
			],
		},
	);

	const [hardDeleteSpace] = useMutation<
		HardDeleteSpaceMutationType,
		HardDeleteSpaceMutationVariables
	>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		HardDeleteSpaceMutation,
		{
			variables: { spaceKey },
			onError: () =>
				onError('deleteError', HARD_DELETE_SPACE_EXPERIENCE, 'Hard delete space mutation failed'),
			onCompleted: (data) => {
				if (data?.hardDeleteSpace?.taskId) {
					// poll the long task's progress
					const taskIdAri = ConfluenceLongRunningTaskAri.create({
						siteId: cloudId,
						activationId,
						taskId: data.hardDeleteSpace.taskId,
					});
					void hardDeleteLongTaskQuery({
						variables: { id: taskIdAri.toString() },
					});
					// show the progress flag
					void flags.showInfoFlag({
						id: 'deleting-space-progress',
						title: <FormattedMessage {...i18n.deletingProgressTitle} />,
						description: (
							<ProgressBar ariaLabel={intl.formatMessage(i18n.deletingProgress)} isIndeterminate />
						),
					});
				}
			},
		},
	);

	useEffect(() => {
		// if the long task is still in progress after 60 seconds, show an error flag
		if (
			longTaskData?.confluence?.longTask?.state?.__typename === 'ConfluenceLongTaskInProgress' &&
			stopPolling
		) {
			timeoutRef.current = setTimeout(() => {
				stopPolling();
				onError('deleteTimeout', HARD_DELETE_SPACE_EXPERIENCE, 'Long task timeout');
			}, POLL_TIMEOUT_MS);
		}
	}, [
		longTaskData?.confluence?.longTask?.state?.__typename,
		stopPolling,
		onError,
		spaceKey,
		createAnalyticsEvent,
	]);

	useEffect(() => {
		if (longTaskError) {
			onError('deleteError', HARD_DELETE_SPACE_EXPERIENCE, longTaskError);
			stopPolling();
			clearTimeout(timeoutRef.current);
		} else if (
			longTaskData?.confluence?.longTask?.state?.__typename === 'ConfluenceLongTaskSuccess'
		) {
			// long task completed successfully, stop polling and show success flag
			stopPolling();
			clearTimeout(timeoutRef.current);
			void flags.hideFlag('deleting-space-progress');
			void flags.showSuccessFlag({
				title: <FormattedMessage {...i18n.successTitle} />,
				isAutoDismiss: true,
			});

			experienceTracker.succeed({
				name: HARD_DELETE_SPACE_EXPERIENCE,
				attributes: {
					spaceId,
				},
			});

			createAnalyticsEvent({
				type: 'sendTrackEvent',
				data: {
					action: 'deleted',
					actionSubject: 'space',
					source,
					containerType: 'space',
					containerId: spaceId,
				},
			}).fire();

			// remove the space from the cache
			modifyTrashedCache && modifyTrashedCache(spaceKey);
		}
	}, [
		flags,
		longTaskData,
		longTaskError,
		stopPolling,
		onError,
		modifyTrashedCache,
		spaceKey,
		spaceId,
		source,
		experienceTracker,
		createAnalyticsEvent,
	]);

	useEffect(() => {
		return () => clearTimeout(timeoutRef.current);
	}, []);

	const handleStartDelete = useCallback(() => {
		experienceTracker.start({
			name: HARD_DELETE_SPACE_EXPERIENCE,
			attributes: {
				spaceId,
			},
		});
		void hardDeleteSpace();
	}, [hardDeleteSpace, experienceTracker, spaceId]);

	const handleRestoreClick = useCallback(() => {
		experienceTracker.start({
			name: RESTORE_SPACE_EXPERIENCE,
			attributes: {
				spaceId,
			},
		});
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'restoreSpaceButton',
				source,
			},
		}).fire();

		void mutateRestoreSpace();
	}, [mutateRestoreSpace, createAnalyticsEvent, source, spaceId, experienceTracker]);

	const handleDeleteClick = useCallback(
		(dialogs: DialogsStateContainer) => {
			dialogs.showModal(DeleteSpaceDialogLoader, {
				handleStartDelete,
			});
		},
		[handleStartDelete],
	);

	return { handleRestoreClick, handleDeleteClick, handleStartDelete };
};
